import Layout from "components/layout";
import SEO from "components/seo";
import React from "react";
import Boston from "components/locationIndexPages/boston/index";

function index() {
  const title = "Boston Translation Services - Translation Agency";
  const description =
    "Are you looking for translation services in Boston? Tomedes provides translation, localization, and interpretation services in 120+ languages with rapid delivery and 24/7 support.";
  const keywords = "Translation Services in boston";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug="/locations/boston-translation-services"
      />
      <Boston />
    </Layout>
  );
}

export default index;
