import React from "react";
import LocationBanner from "components/locationBanner/index";
import EmailerLocation from "components/LocationReusableComp/emailer";
import TranslateAgency from "components/homepage/translateAgency";
import CommonUse from "components/LocationReusableComp/commonuse";
import ServicesGrid from "components/homepage/servicesGrid";
import { Link } from "gatsby";
import LookingTranslator from "components/LocationReusableComp/looking";
import Popular from "components/LocationReusableComp/popular";
import { TranslationInBoston, LanguagesInBoston, BostonData } from "./config";
import WhatChooseUs from "components/homepage/whyChooseUs";
import Reviews from "components/homepage/reviews";
import TranslationServices from "components/homepage/translationServices";
import GetInTouch from "components/getInTouch";
const index = () => {
  return (
    <>
      <LocationBanner
        backgroundCol="bg-boston-banner"
        title="Boston Translation Services"
        content="Have you been searching for the best translation services in Boston, Massachusetts? Your search is over. Tomedes translation company has been supporting businesses and individuals in Boston in 120+ languages for more than a decade now. Click the button below for a quote, no commitment."
        formOrangeHead="Get in touch  "
        formWhiteHead=" with our translation company"
      />
      <EmailerLocation />
      <TranslateAgency content="If you’re looking for an international translation company in Boston, Tomedes has you covered. About 194,000 of Boston’s residents are from different countries, so it’s imperative to find translation services for Boston that can relate to this demographic and beyond. With the client at the heart of our philosophy, our clients have rated us 4.8 out of 5 stars, with a 97% client satisfaction rate. You can trust Tomedes to broaden your horizons." />
      <CommonUse
        backgroundCol="bg-midBlue"
        title="Translation Services for every industry in Boston"
        para="Don’t you want the best for your business? Partnering with Tomedes would provide you with the most popular services for almost every industry in Boston, such as:"
        data={BostonData}
      />
      <ServicesGrid
        backgroundCol="bg-darkBlue"
        heading="Didn’t See Your Industry?"
        details="Tomedes translation company is available for more translation services, ranging from translating your marketing materials to branding concerns to localizing your website. Our multilingual Boston interpreters, localizers, and translators have you covered. Reach out to Tomedes for a quote."
        contactUs={
          <Link
            to="/contactus.php"
            className="en-btn bg-orange text-white rounded-3xl uppercase py-2 px-8 mt-6 inline-block  mlap:mb-10"
          >
            Contact us
          </Link>
        }
      />
      <LookingTranslator
        picture="https://tomedes.gumlet.io/frontend/images/location_new/cta_boston.webp"
        translationAt="for a Translator in Boston?"
        detail="A Boston translator is just a call away at Tomedes. Your translations will be secured and private in the process, as well as certified and authenticated in the output. If you're in need of a translator in Boston, call Tomedes. We're available 24/7 and we respond in 30 minutes."
        location="Boston "
        countryName="USA"
      />
      <Popular
        backgroundCol="bg-darkBlue"
        languageCount="Popular Translation Services in Boston"
        bottomHeading="Certified Translation Services"
        bottomDetails="Tomedes translation agency in Boston can make sure you provide the necessary <a href='/certified-translation.php' class='text-orange hover:text-newOrange-100 transition-colors'>certified translation</a> for all your applications or processes. This means that translations must be certified by a certified translator to ensure that the translations follow particular procedures. For example, here are some of the certified translation services for Boston:"
        location=" "
        data={TranslationInBoston}
        bottomBorderText
        footerTitle="Document Translation Services"
        footerDetails="Tomedes can translate any document using the expertise of linguists around the globe. We can translate your documents, even for rare languages and rare industries. For applications at universities, or for immigration purposes, Tomedes has your needs at heart--we can translate your birth certificates, academic transcripts, marketing messages, and more."
        bottomBorderTextLink="https://www.tomedes.com/document-translation-services"
      />
      <Popular
        bottomBorderText
        backgroundCol="bg-midBlue"
        languageCount="Translation Services in 120 Languages and 950+ Language Pairs"
        details="Tomedes knows native translators are the best translators. We've handpicked translators all over the world to translate these popular languages in Boston:"
        bottomHeading="Popular Languages in"
        location="Boston"
        data={LanguagesInBoston}
      />
      <WhatChooseUs backgroundCol="bg-darkBlue" />
      <Reviews backgroundCol="bg-midBlue" />
      <TranslationServices backgroundCol="bg-darkBlue" />
      <GetInTouch />
    </>
  );
};

export default index;
